import { ObjectType } from "@src/Clients/Perminator/data-contracts";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanWriteOrgSettings(orgId: string) {
  return usePermissionCheck({
    objectType: ObjectType.OrgSettings,
    objectId: orgId,
    relation: "write"
  });
}
export function useCanReadOrgSettings(orgId: string) {
  return usePermissionCheck({
    objectType: ObjectType.OrgSettings,
    objectId: orgId,
    relation: "read"
  });
}
