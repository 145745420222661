import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Clickable } from "@src/Components/Buttons/Clickable";

import { quickRangeOptions, TimeRangeOption } from "./rangeOptions";

const RangeListWrapper = styled.div`
  position: relative;
  height: 100%;
  border-left: 1px solid #ccc;
  overflow: auto; /* Need to style this */
`;

export const SectionTitle = styled.h4`
  margin: 0;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
  background-color: white;
`;

const RangesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

interface QuickRangeListProps {
  applyRange: (range: [string, string], alias: string) => void;
}

export function QuickRangeList({ applyRange }: QuickRangeListProps) {
  return (
    <RangeListWrapper>
      <SectionTitle>Pick a quick range</SectionTitle>
      <RangesList>
        {quickRangeOptions.map(option => (
          <QuickRangeOption key={option.label} applyRange={applyRange} {...option} />
        ))}
      </RangesList>
    </RangeListWrapper>
  );
}

const RangeOptionButton = styled(Clickable)<{ $active: boolean }>`
  width: 100%;
  background-color: ${({ theme, $active }) => $active && theme.primary + "50"};
  &:hover {
    background-color: ${({ theme }) => theme.primary + "50"};
  }
`;

const RangeListItem = styled.li`
  padding: 8px;
`;

interface QuickRangeOptionProps extends TimeRangeOption {
  applyRange: (range: [string, string], alias: string) => void;
}

export function QuickRangeOption({ from, to, label, applyRange }: QuickRangeOptionProps) {
  const [searchParams] = useSearchParams();
  const alias = searchParams.get("alias");

  const applyQuickRange = useCallback(() => {
    applyRange([from, to], label);
  }, [applyRange, from, label, to]);

  const displayLabel = label.replace(/-/g, " ");
  return (
    <RangeOptionButton onClick={applyQuickRange} $active={alias === label}>
      <RangeListItem>{displayLabel}</RangeListItem>
    </RangeOptionButton>
  );
}
