import React, { Dispatch, ReactNode, SetStateAction, useCallback } from "react";
import { CSVLink } from "react-csv";
import { Headers } from "react-csv/components/CommonPropTypes";
import { Navigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { DismissModalButton } from "@src/Components/Modal/DismissModalButton";
import { Modal } from "@src/Components/Modal/Modal";
import { PageSizeInput } from "@src/Components/Table/PageSizeInput";
import { H2 } from "@src/Components/Text";
import { EventLogEntry } from "@src/Generated/graphql";
import { useFilterByFields } from "@src/Hooks/filterByFields";
import { InputLabel } from "@src/ServicesDashboard/Services/InstancePanel/ConnectionLogs/InputLabel";

import { AuditLogNode, FetchLogsResponse } from "./fetchLogs";
import { LogsColumnFilter } from "./FilterColumns/LogsColumnFilter";
import { useLogNavigation } from "./logNavigation";
import { ReportsColumns, ReportsLogsTable } from "./ReportsLogsTable";
import { DateRangePicker } from "./TimeFilter/DateRangePicker";
import { validateParams } from "./TimeFilter/dateUtils";

export const TabTitle = styled(H2)`
  margin-bottom: 0px;
  margin-top: 28px;
`;

export const Message = styled.span`
  display: block;
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UnresolvedField = styled.span`
  color: #bbb;
`;

const LogActionButton = styled(PrimaryButton)`
  height: auto;
  width: auto;
  padding: 10px 20px;
  &:disabled {
    & a {
      pointer-events: none;
    }
  }
`;

const DownloadCVSLink = styled(CSVLink)`
  text-decoration: none;
  color: inherit;
`;

const TableActionButtons = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const FirstLine = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto 1fr;
  align-items: flex-end;
`;

export interface ReportLogsProps extends FetchLogsResponse {
  csvHeaders: Headers;
  csvFileName: string;
  columns: ReportsColumns;
  allColumns: string[];
  hideableColumns: string[];
  selectedLog: AuditLogNode | EventLogEntry;
  setSelectedLog: Dispatch<SetStateAction<AuditLogNode | EventLogEntry>>;
  children: ReactNode;
}

export function ReportLogs({
  csvHeaders,
  csvFileName,
  columns,
  allColumns,
  hideableColumns,
  selectedLog,
  setSelectedLog,
  children,
  ...logsResponse
}: ReportLogsProps) {
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const pageSize = searchParams.get("pageSize");
  const { logs, loading, refetch, pageInfo, totalCount } = logsResponse;

  const updateQueryParams = useLogNavigation();

  const openDetailsModal = useCallback(
    (log: AuditLogNode) => {
      setSelectedLog(log);
    },
    [setSelectedLog]
  );

  const closeModal = useCallback(() => {
    setSelectedLog(null);
  }, [setSelectedLog]);

  const { toggleFilterTag, selectedFilters } = useFilterByFields(hideableColumns);

  const validParams = validateParams(from, to, pageSize);

  if (!validParams) return <Navigate to={`?alias=Last-24-hours&from=now-24h&to=now&pageSize=10`} />;

  return (
    <>
      <FirstLine>
        <DateRangePicker />
        <PageSizeInput
          queryParamName="pageSize"
          PickerLabel={InputLabel}
          customUpdateFunction={updateQueryParams}
        />
        <LogsColumnFilter
          allColumns={allColumns}
          hideableColumns={hideableColumns}
          toggleFilterTag={toggleFilterTag}
          selectedFilters={selectedFilters}
        />
        <TableActionButtons>
          <LogActionButton onClick={() => refetch()}>Refresh</LogActionButton>
          <LogActionButton disabled={logs.length === 0}>
            <DownloadCVSLink data={logs} headers={csvHeaders} filename={csvFileName}>
              Export
            </DownloadCVSLink>
          </LogActionButton>
        </TableActionButtons>
      </FirstLine>

      <ReportsLogsTable
        reportLogs={logs}
        columns={columns}
        hideableColumns={hideableColumns}
        openDetailsModal={openDetailsModal}
        selectedFilters={selectedFilters}
        fetchLogs={refetch}
        pageInfo={pageInfo}
        totalCount={totalCount}
        loading={loading}
      />

      <Modal show={!!selectedLog} onOutsideClick={closeModal}>
        <>
          <DismissModalButton onClick={closeModal} />
          {children}
        </>
      </Modal>
    </>
  );
}
