import axios from "axios";
import { useQuery as useReactQuery } from "react-query";

type LogoPath = "auth" | "nav";

interface LogoUrl {
  path: string;
}

const fetchLogos = (path: string): Promise<LogoUrl> =>
  axios.get(`/upload/public/key/${path}`).then(response => response.data);

export function useBrandingLogos(path: LogoPath) {
  const { data } = useReactQuery([`${path}-logo`], () => fetchLogos(path));

  return data?.path;
}
