import { UseSelectStateChange } from "downshift";
import { FormikErrors } from "formik";
import React, { useCallback } from "react";

import { InputErrors, PaddedError } from "@src/Components/Input/InputGroup";

import { CoresDropdown } from "../CoreDropdown";
import { useFetchCoreList } from "../Infrastructure/coresRequests";
import { NetworkElement } from "../Infrastructure/NetworkElement";

interface CoreDropdownInputProps<E> {
  name: string;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
  coreId: string;
  errors: FormikErrors<E>;
}

export function CoreDropdownInput<E>({
  name,
  setFieldValue,
  coreId,
  errors
}: CoreDropdownInputProps<E>) {
  const { data: coresList } = useFetchCoreList(["core-dropdown-input"], {
    cacheTime: 0,
    refetchOnWindowFocus: false
  });

  const initialCore = coresList.find(core => core.uid === coreId);

  const onSelectCore = useCallback(
    (change: UseSelectStateChange<NetworkElement>) => {
      setFieldValue(name, change.selectedItem.uid);
    },
    [name, setFieldValue]
  );

  if (!coresList) return null;
  return (
    <>
      <CoresDropdown
        coresList={coresList}
        initialCore={initialCore}
        onSelectCore={onSelectCore}
        allowEmptyStart={true}
      />
      <PaddedError>
        <InputErrors errors={errors} name={name} />
      </PaddedError>
    </>
  );
}
