import { UseComboboxReturnValue } from "downshift";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { dropdownMenuZIndex } from "./zIndexValues";

export const MenuAnchor = styled.div`
  position: relative;
`;

export const Menu = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  z-index: ${dropdownMenuZIndex};
  max-height: 500px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 6px;
`;

export interface DropdownItem {
  id: string;
  index?: number;
  displayName: string;
  disabled?: boolean;
  message?: string;
}

export interface RenderItemProps<T> {
  item: T;
  comboboxProps: UseComboboxReturnValue<T>;
}

export type RenderItem<T> = (props: RenderItemProps<T>) => ReactNode;

export interface DropSection<T> {
  section: string;
  data: T[];
}
interface DropdownMenuProps<T extends DropdownItem> extends UseComboboxReturnValue<T> {
  data: T[] | { section: string; data: T[] }[];
  children?: ReactNode;
  renderItem?: RenderItem<T>;
}

const SectionHeader = styled.h4`
  text-transform: capitalize;
  padding: 0 14px 4px;
  margin-bottom: 0;
  border-bottom: 1px solid #cccccc;
`;

function hasSections<T>(data: T[] | DropSection<T>[]): data is DropSection<T>[] {
  return data.length && "section" in (data[0] as DropSection<T>);
}

export function DropdownMenu<T extends DropdownItem>({
  data,
  children,
  renderItem,
  ...comboboxProps
}: DropdownMenuProps<T>) {
  const { isOpen, getMenuProps } = comboboxProps;

  return (
    <MenuAnchor {...getMenuProps()}>
      {isOpen && data?.length > 0 ? (
        <Menu>
          {children}
          {!hasSections(data)
            ? data.map((item, i) => (
                <DropdownMenuItem
                  key={item.id}
                  item={item}
                  index={i}
                  renderItem={renderItem}
                  {...comboboxProps}
                />
              ))
            : data.reduce(
                (prev, { section, data }, i, array) => {
                  const offset = prev.offset + (i > 0 ? array[i - 1].data.length : 0);
                  return {
                    offset,
                    children: [
                      ...prev.children,
                      <SectionHeader key={section}>{section}</SectionHeader>,
                      ...data.map((item, i) => (
                        <DropdownMenuItem
                          key={item.id}
                          item={item}
                          index={offset + i}
                          renderItem={renderItem}
                          {...comboboxProps}
                        />
                      ))
                    ]
                  };
                },
                { offset: 0, children: [] as ReactNode[] }
              ).children}
        </Menu>
      ) : null}
    </MenuAnchor>
  );
}

export const DropdownMenuItemWrapper = styled.div<{
  $highlighted?: boolean;
  $isDisabled?: boolean;
}>`
  color: ${({ $highlighted }) => ($highlighted ? "#3c9bd8" : "#444444")};
  background-color: ${({ $highlighted }) => ($highlighted ? "rgba(60, 155, 216, 0.2)" : "unset")};
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  padding: 0 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.4 : 1)};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};
`;

interface DropdownMenuItemProps<T extends DropdownItem> extends UseComboboxReturnValue<T> {
  item: T;
  index: number;
  renderItem?: RenderItem<T>;
}

function DropdownMenuItem<T extends DropdownItem>({
  item,
  index,
  renderItem,
  ...comboboxProps
}: DropdownMenuItemProps<T>) {
  const { getItemProps, highlightedIndex } = comboboxProps;
  return (
    <DropdownMenuItemWrapper
      {...getItemProps({
        index,
        item
      })}
      key={item.id}
      $highlighted={index === highlightedIndex}
      $isDisabled={item.disabled}
      title={item.message}
    >
      {renderItem ? renderItem({ item, comboboxProps }) : item.displayName}
    </DropdownMenuItemWrapper>
  );
}

export function itemToString(item: DropdownItem): string {
  return item?.displayName || "";
}
