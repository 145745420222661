import React from "react";

import MonitoringIcon from "@img/monitoring.svg";
import NetworkIcon from "@img/network.svg";
import ReportsIcon from "@img/reports.svg";
import TroubleshootIcon from "@img/screwdriver-wrench-solid.svg";
import SimCardIcon from "@img/sim-card.svg";
import ConfigureIcon from "@img/sliders-solid.svg";
import SettingsIcon from "@img/system.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/orgCtx";

import { useSelectedNetwork } from "./networks";

export function MPNNavbar() {
  const { network } = useSelectedNetwork();
  const orgCtx = useOrgCtx();

  const rootPath = `/app/${orgCtx}/mpn/${network?.uid}`;

  return (
    <NavIcons>
      <NavItem
        linkTo={rootPath + "/operate"}
        label="Operate"
        icon={MonitoringIcon}
        disabled={!network}
      />
      <NavItem
        linkTo={rootPath + "/configure"}
        label="Configure"
        icon={ConfigureIcon}
        disabled={!network}
      />
      <NavItem
        linkTo={rootPath + "/sim-cards"}
        label="SIMs"
        icon={SimCardIcon}
        disabled={!network}
      />
      <NavItem
        linkTo={rootPath + "/infrastructure"}
        label="Infrastructure"
        icon={NetworkIcon}
        disabled={!network}
      />
      <NavItem
        linkTo={rootPath + "/troubleshoot"}
        label="Troubleshoot"
        icon={TroubleshootIcon}
        disabled={!network}
      />
      <NavItem
        linkTo={rootPath + "/reports"}
        label="Reports"
        icon={ReportsIcon}
        disabled={!network}
      />
      <NavItem
        linkTo={rootPath + "/settings"}
        label="Settings"
        icon={SettingsIcon}
        disabled={!network}
      />
    </NavIcons>
  );
}
