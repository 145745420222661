import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { clients } from "@src/Clients/Apollo/apolloClient";
import { useSession } from "@src/Session";

import { kratos } from "./kratos";

// Returns a function which will log the user out
export function useLogoutHandler() {
  const navigate = useNavigate();
  const [logoutToken, setLogoutToken] = useState<string>("");
  const { logoutSession } = useSession();

  useEffect(() => {
    if (MOCK_USERINFO) return;

    kratos
      .createBrowserLogoutFlow()
      .then(({ data }) => {
        setLogoutToken(data.logout_token);
      })
      .catch((err: { response: { status: number } }) => {
        switch (err.response?.status) {
          case 401:
            // do nothing, the user is not logged in
            return;
        }

        // Something else happened!
        return Promise.reject(err);
      });
  }, []);

  return useCallback(() => {
    if (MOCK_USERINFO) return;

    if (logoutToken) {
      kratos
        .updateLogoutFlow({ token: logoutToken })
        .then(() => clients.forEach(c => c.clearStore()))
        .then(() => {
          logoutSession();
          navigate("/auth/login");
        });
    }
  }, [logoutToken, navigate, logoutSession]);
}
