import React from "react";
import styled from "styled-components";

import { useLogoutHandler } from "@src/Auth/Logout";
import { DangerButton } from "@src/Components/Buttons/Danger";
import { H1, H3 } from "@src/Components/Text";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 30px 25%;
  gap: 30px;
  & button {
    align-self: flex-end;
  }
`;
export function NoOrg() {
  const logout = useLogoutHandler();

  return (
    <Wrapper>
      <H1>User has no associated Org</H1>
      <H3>Contact your System Admin to be invited to an Org.</H3>
      <DangerButton onClick={logout}>Sign Out</DangerButton>
    </Wrapper>
  );
}
