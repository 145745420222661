interface InventoryField {
  name: string;
  value: string | number;
}
export interface InventoryResponse {
  resourceCharacteristic: InventoryField[];
  resourceStatus: string;
}

export function serialiseNetworkResources(inventoryData: InventoryResponse[]) {
  return (inventoryData || []).map(inventoryField => {
    return serialiseField(inventoryField);
  });
}

export function serialiseField(field: InventoryResponse) {
  return (field?.resourceCharacteristic || []).reduce(
    (acc, el) => {
      const key = el.name.toLowerCase();
      return {
        ...acc,
        [key]: el.value
      };
    },
    { type: "core", status: field?.resourceStatus }
  );
}
