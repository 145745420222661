import { NetworkElement, NetworkElementType } from "./Infrastructure/NetworkElement";

export const allCoresOption: NetworkElement = {
  uid: "all-cores",
  displayname: "All Cores",
  type: NetworkElementType.Core,
  status: "unknown",
  latitude: 0,
  longitude: 0,
  chartname: "",
  charttype: "",
  chartversion: "",
  config: []
};
