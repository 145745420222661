import styled, { css } from "styled-components";

import Stop from "@img/circle-stop-solid.svg";
import Console from "@img/console.svg";
import Download from "@img/download.svg";
import Edit from "@img/edit.svg";
import Eye from "@img/eye.svg";
import GroupAdd from "@img/group-add.svg";
import Key from "@img/key-solid.svg";
import Logs from "@img/logs.svg";
import Remove from "@img/remove.svg";
import Scroll from "@img/scroll-solid.svg";
import Recruit from "@img/sitemap-solid.svg";
import User from "@img/user-filled.svg";

import { iconSize } from "../iconSize";

const commonIconStyles = css`
  ${iconSize}
  color: ${({ theme }) => theme.primary};
  fill: ${({ theme }) => theme.primary};
`;

export const EyeIcon = styled(Eye)`
  ${commonIconStyles};
`;

export const RemoveIcon = styled(Remove)`
  ${commonIconStyles};
`;

export const EditIcon = styled(Edit)`
  ${commonIconStyles};
`;

export const UserIcon = styled(User)`
  ${commonIconStyles};
`;

export const RecoveryIcon = styled(Key)`
  ${commonIconStyles};
  width: 16px;
`;

export const RecruitIcon = styled(Recruit)`
  ${commonIconStyles};
`;

export const GroupAddIcon = styled(GroupAdd)`
  ${commonIconStyles};
  width: 30px;
`;

export const DownloadIcon = styled(Download)`
  ${commonIconStyles};
  width: 20px;
  height: 20px;
`;

export const StopIcon = styled(Stop)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.red};
`;

export const ConsoleIcon = styled(Console)`
  ${commonIconStyles}
`;

export const ScrollIcon = styled(Scroll)`
  ${commonIconStyles}
`;

export const LogsIcon = styled(Logs)`
  ${commonIconStyles}
`;
