import React, { ChangeEvent, HTMLAttributes, KeyboardEvent, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled, { IStyledComponent } from "styled-components";

import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Input } from "@src/Components/Input/Input";
import { InputLabel } from "@src/ServicesDashboard/Services/InstancePanel/ConnectionLogs/InputLabel";

export const PageSizeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 225px;
`;

export const FilterButton = styled(PrimaryButton)`
  width: auto;
  padding: 2px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const SearchInput = styled(Input)`
  padding: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const Label = styled(InputLabel)`
  font-size: 14px;
`;

interface PageSizeInputProps {
  queryParamName?: string;
  customUpdateFunction?: ({ size }: { size: number }) => void;
  PickerLabel?: IStyledComponent<"web", HTMLAttributes<HTMLLabelElement>>;
}

export function PageSizeInput({
  queryParamName = "size",
  PickerLabel = Label,
  customUpdateFunction
}: PageSizeInputProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const size = searchParams.get(queryParamName);

  const [sizeInput, setSizeInput] = useState(size);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSizeInput(e.target.value);
    },
    [setSizeInput]
  );

  const updateSizeParam = useCallback(() => {
    if (customUpdateFunction) customUpdateFunction({ size: +sizeInput });
    else setSearchParams({ page: "1", size: sizeInput });
  }, [customUpdateFunction, setSearchParams, sizeInput]);

  const handlePressEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== "Enter") return;
      updateSizeParam();
    },
    [updateSizeParam]
  );

  return (
    <PageSizeWrapper>
      <PickerLabel>Page Size:</PickerLabel>
      <SearchInput
        type="number"
        placeholder="10"
        value={sizeInput}
        min={1}
        onChange={handleInputChange}
        onKeyDown={handlePressEnter}
      />
      <FilterButton onClick={updateSizeParam}>Update</FilterButton>
    </PageSizeWrapper>
  );
}
