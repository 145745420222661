import { FieldArray, FormikErrors } from "formik";
import React from "react";
import styled from "styled-components";

import { FieldLabel, Fieldset } from "@src/Components/Input/InputGroup";
import { ConfigField, ValueType } from "@src/DynamicForm/initFormConfig";
import {
  ConfigInput,
  NoDots
} from "@src/ServicesDashboard/Infrastructure/Inventory/CloudResource/ConfigInput";

const ConfigFieldLabel = styled(FieldLabel)<{ $selected: boolean }>`
  opacity: ${({ $selected }) => !$selected && "0.4"};
`;

interface ConfigFieldsFormProps<N extends string, E> {
  name: NoDots<N>;
  configFields: ConfigField[];
  errors: FormikErrors<E>;
  isEdit: boolean;
  setFieldValue: (field: string, value: ValueType) => void;
}

export function ConfigFieldsForm<N extends string, E>({
  name,
  configFields,
  errors,
  isEdit,
  setFieldValue
}: ConfigFieldsFormProps<N, E>) {
  const config = configFields || [];

  return (
    <FieldArray
      name={name}
      render={() => (
        <Fieldset>
          {config.map(({ label, ...fieldInfo }, index) => (
            <React.Fragment key={label}>
              <ConfigFieldLabel htmlFor={label} $selected={fieldInfo.selected}>
                {label}
              </ConfigFieldLabel>
              <ConfigInput
                name={name}
                fieldInfo={fieldInfo}
                errors={errors}
                index={index}
                isEdit={isEdit}
                setFieldValue={setFieldValue}
              />
            </React.Fragment>
          ))}
        </Fieldset>
      )}
    />
  );
}
