// redirectOrg returns the new path that we should navigate to when switching networks
// it strips any suffix after /mpn/ so that we aren't left with an invalid combination of
// selected Org and Network
export function redirectOrg(pathname: string, org: string) {
  const match = pathname.match(/^\/app\/[^/]+\/(?<dashboard>[^/]+)(?<path>\/.*)?/);
  if (match && match.groups) {
    switch (match.groups.dashboard) {
      case "mpn":
        return `/app/${org}/${match.groups.dashboard}`;
      case "services":
      default:
        return `/app/${org}/${match.groups.dashboard}${match.groups.path || ""}`;
    }
  }
}
