import React, { useCallback } from "react";
import styled from "styled-components";

import { Clickable } from "@src/Components/Buttons/Clickable";
import { PageInfo } from "@src/Generated/graphql";

import { RefetchVariables } from "./fetchLogs";

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

interface LogsPaginationProps {
  fetchLogs: (variables?: Partial<RefetchVariables>) => void;
  pageInfo: PageInfo;
  totalCount: number;
  logsShown: number;
}

export function LogsPagination({
  fetchLogs,
  pageInfo,
  totalCount,
  logsShown
}: LogsPaginationProps) {
  const { startCursor, endCursor, hasNextPage, hasPreviousPage } = pageInfo;

  const fetchNextLogs = useCallback(() => {
    fetchLogs({ afterCursor: endCursor, beforeCursor: null });
  }, [endCursor, fetchLogs]);

  const fetchPreviousLogs = useCallback(() => {
    fetchLogs({ beforeCursor: startCursor, afterCursor: null });
  }, [startCursor, fetchLogs]);

  return (
    <Pagination>
      <Clickable onClick={fetchPreviousLogs} disabled={!hasPreviousPage}>
        {"<"}
      </Clickable>
      <p>
        Showing {logsShown} of {totalCount} logs
      </p>
      <Clickable onClick={fetchNextLogs} disabled={!hasNextPage}>
        {">"}
      </Clickable>
    </Pagination>
  );
}
