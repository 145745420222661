import { Link } from "react-router-dom";
import styled from "styled-components";

export const sidebarWidth = "100px";

export const Page = styled.div`
  display: grid;
  position: relative;
  grid-template-areas:
    "sidebar header"
    "sidebar main"
    "sidebar panel";
  grid-template-columns: ${sidebarWidth} auto;
  grid-template-rows: 50px 1fr;
  min-height: 100vh;
`;

export const Sidebar = styled.nav`
  grid-area: sidebar;
  background-image: linear-gradient(
      195deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 171px,
      #333167 171px,
      #333167
    ),
    linear-gradient(180deg, rgba(16, 73, 152, 0.48), rgba(218, 40, 63, 0.48) 300px);
  background-color: var(--nav-bar-background-color, #333167);

  text-align: center;

  min-height: 100%;
  display: grid;
  grid-template-rows: min-content auto auto;
`;

export const Logo = styled(Link)`
  width: 90px;
  height: 134px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const NavIcons = styled.div`
  margin: 90px 0 139px;

  position: sticky;
  top: 0;
`;

export const Main = styled.main`
  grid-area: main;
  position: relative;
  padding: 0 60px 30px;
`;

export const LoggedOutWarning = styled.div`
  padding: 0.5em 60px;
  background-color: ${props => props.theme.warning};
  color: white;
  text-align: center;
`;
