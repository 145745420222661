import { ObjectType } from "@src/Clients/Perminator/data-contracts";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanCreateSite(id: string) {
  return usePermissionCheck({
    objectType: ObjectType.Org,
    objectId: id,
    relation: "createSite"
  });
}

export function useCanEditSite(id: string) {
  return usePermissionCheck({
    objectType: ObjectType.Site,
    objectId: id,
    relation: "write"
  });
}

export function useCanDeleteSite(id: string) {
  return usePermissionCheck({
    objectType: ObjectType.Site,
    objectId: id,
    relation: "delete"
  });
}
