import { styled } from "styled-components";

import { baseButtonStyles } from "@src/Components/Buttons/Base";

export const FieldInput = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

export const UserSettingsNodeInput = styled.input`
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid rgb(221, 221, 221);
  font-size: 14px;
  font-weight: 500;
  padding: 14px 14px 15px;
  color: rgb(124, 132, 149);

  &[type="submit"] {
    ${baseButtonStyles}
    grid-column: 2;
    cursor: pointer;
    width: 200px;
    background-color: #484886;
    color: white;
  }
`;
