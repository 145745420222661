import { parseQuickRangeOption, supportedQuickUnits, validTimeStrings } from "./rangeOptions";

export function getUTCDate(timeStamp = Date.now()) {
  const tzOffset = new Date().getTimezoneOffset() * 60000;
  return new Date(timeStamp - tzOffset);
}

export function dateToFormattedString(timeStamp: number) {
  return getUTCDate(timeStamp).toLocaleString(undefined, { timeZone: "UTC" });
}

export function validateParams(from: string, to: string, pageSize: string) {
  const isStartValid = !!Number(from) || validTimeStrings.includes(from);
  const isEndValid = !!Number(to) || validTimeStrings.includes(to);
  const isDateConsistent =
    Number(from) < Number(to) || validTimeStrings.indexOf(from) > validTimeStrings.indexOf(to);
  const pageSizeValid = !!Number(pageSize) && Number(pageSize) > 0;

  return isStartValid && isEndValid && isDateConsistent && pageSizeValid;
}

export function calculateCalendarState(from: string, to: string): [Date, Date] {
  const isQuickRange = !Number(from) && !Number(to);
  if (isQuickRange)
    return [new Date(parseQuickRangeOption(from)), new Date(parseQuickRangeOption(to))];

  return [new Date(Number(from)), new Date(Number(to))];
}

export function parseRange(start: string, end: string, testDate?: Date): [string, string] {
  if (!start || !end) return ["", ""];
  if (!!Number(start) && !!Number(end)) {
    const from = getUTCDate(+start).toISOString();
    const to = getUTCDate(+end).toISOString();
    return [from, to];
  }

  if (supportedQuickUnits.includes(start.slice(-1))) return [start, end];

  return [
    getUTCDate(parseQuickRangeOption(start, testDate)).toISOString().slice(0, -7) + "00.000Z",
    getUTCDate(parseQuickRangeOption(end, testDate)).toISOString().slice(0, -7) + "00.000Z"
  ];
}
