import styled, { css } from "styled-components";

import { baseButtonStyles } from "./Base";

export const secondaryButtonStyles = css`
  ${baseButtonStyles}

  background-color: #3c9bd8;
  color: white;
  fill: white;

  &:hover {
    color: #c0e2f4;
    fill: #c0e2f4;
  }

  &:disabled {
    background-color: rgba(60, 155, 216, 0.1);
    color: #3c9bd8;
  }
`;

export const SecondaryButton = styled.button`
  ${secondaryButtonStyles}
`;
