import styled from "styled-components";

import { baseButtonStyles } from "./Base";

export const GhostButton = styled.button`
  ${baseButtonStyles}

  border: solid 1px #868ab1;
  background-color: #f8f4f4;
  color: #484886;

  &:hover {
    border-color: #b5b7d0;
    background-color: #f8f4f4;
    color: #484886;
  }

  &:disabled {
    border-color: #e0dde6;
    background-color: #f8f4f4;
    color: #484886;
  }
`;
