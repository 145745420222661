import { Configuration, FrontendApi, PermissionApi, RelationshipApi } from "@ory/client";

export const kratos = new FrontendApi(
  new Configuration({
    basePath: "/.ory/kratos/public"
  })
);

export const permissions = new PermissionApi(
  new Configuration({
    basePath: "/perms"
  })
);

export const relationships = new RelationshipApi(
  new Configuration({
    basePath: "/perms"
  })
);

export const fetchErrors = async (id: string) => {
  try {
    return (await kratos.getFlowError({ id })).data;
  } catch (err) {
    console.error(err);
  }
};
