import React from "react";
import styled, { keyframes } from "styled-components";

import LoadingIcon from "@img/nbycomp-loading.svg";

const fadeIn = (step: number) => keyframes`
  0% { opacity: 0; }
  ${`${20 + 10 * (step - 1)}%`} { opacity: 0; }
  ${`${20 + 10 * step}%`} { opacity: 0.85; }
  100% { opacity: 0.85; }
`;

export const AnimatedLoadingIcon = styled(LoadingIcon)`
  width: 106.493px;
  height: 106.85px;

  .nbycomp-loading_svg__purple,
  .nbycomp-loading_svg__red,
  .nbycomp-loading_svg__blue,
  .nbycomp-loading_svg__navy,
  .nbycomp-loading_svg__orange,
  .nbycomp-loading_svg__yellow {
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .nbycomp-loading_svg__purple {
    animation-name: ${fadeIn(1)};
  }
  .nbycomp-loading_svg__red {
    animation-name: ${fadeIn(2)};
  }
  .nbycomp-loading_svg__blue {
    animation-name: ${fadeIn(3)};
  }
  .nbycomp-loading_svg__navy {
    animation-name: ${fadeIn(4)};
  }
  .nbycomp-loading_svg__orange {
    animation-name: ${fadeIn(5)};
  }
  .nbycomp-loading_svg__yellow {
    animation-name: ${fadeIn(6)};
  }
`;

const LoadingContainer = styled.div<{ $margin: string }>`
  margin: ${({ $margin }) => $margin || "120px 0"};
`;

const CenteredLoadingIcon = styled(AnimatedLoadingIcon)`
  display: block;
  margin: 0 auto;
`;

const LoadingText = styled.p`
  text-align: center;
  font-size: 20px;
`;

interface LoadingProps {
  customText?: string;
  margin?: string;
}

export function Loading({ customText, margin }: LoadingProps) {
  return (
    <LoadingContainer $margin={margin}>
      <CenteredLoadingIcon />
      <LoadingText>{customText || "loading"}...</LoadingText>
    </LoadingContainer>
  );
}

const InlineLoadingIcon = styled(AnimatedLoadingIcon)`
  height: 1em;
  width: 1em;
  vertical-align: middle;
`;

export function InlineLoading({ customText }: LoadingProps) {
  return (
    <>
      {customText || "loading"}... <InlineLoadingIcon />
    </>
  );
}
