import { useCallback } from "react";

import { ExpandedObject, useSiteTree } from "@src/Hooks/siteTree";

interface UseSiteIdSelectorProps {
  ancestors?: string[];
  selectedSite?: string;
  onSelectSite: (siteId: string, expanded?: ExpandedObject) => void;
}

export function useSiteIdSelector({
  ancestors,
  selectedSite,
  onSelectSite
}: UseSiteIdSelectorProps) {
  const treeProps = useSiteTree({ nav: null, ancestors, selectedSite });
  const siteIdSelected = treeProps.state?.selected;
  const expandedState = treeProps.state?.expanded;

  const handleClick = useCallback(() => {
    onSelectSite(siteIdSelected, expandedState);
  }, [siteIdSelected, expandedState, onSelectSite]);

  return { treeProps, siteIdSelected, handleClick };
}
