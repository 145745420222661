import { useQuery } from "@apollo/client";
import {
  FetchInfraBlockChartQuery,
  FetchInfraBlockChartQueryVariables,
  FetchInfraChartListQuery,
  FetchInfraChartListQueryVariables,
  InfraChartType
} from "@src/Generated/graphql";

import FetchInfraBlockChart from "./FetchInfraBlockChart.graphql";
import FetchInfraChartList from "./FetchInfraChartList.graphql";

export const networkRegistrationChartName = "mn-network-registration";

export function useLatestNetworkRegistrationChart() {
  const {
    data: chartList,
    loading: loadingChartsList,
    error: chartsListError
  } = useQuery<FetchInfraChartListQuery, FetchInfraChartListQueryVariables>(FetchInfraChartList, {
    variables: {
      type: InfraChartType.MnNetwork
    }
  });

  const networkRegistrationCharts = (chartList?.infraBlockCharts || []).filter(
    ({ name }) => name === networkRegistrationChartName
  );
  const latestChartVersion = networkRegistrationCharts[0]?.version;

  const {
    data: chart,
    loading: loadingChart,
    error: chartError
  } = useQuery<FetchInfraBlockChartQuery, FetchInfraBlockChartQueryVariables>(
    FetchInfraBlockChart,
    {
      variables: {
        name: networkRegistrationChartName,
        version: latestChartVersion,
        type: InfraChartType.MnNetwork
      },
      skip: !latestChartVersion
    }
  );

  return {
    chart: chart?.infraBlockChart,
    version: latestChartVersion,
    loading: loadingChartsList || loadingChart,
    error: chartsListError ?? chartError
  };
}
