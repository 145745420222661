import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { LoginFlow, UpdateLoginFlowBody } from "@ory/client";
import { getNodeId, useSelfService } from "@src/Hooks/selfService";

import { handleGetFlowError } from "./errors";
import { Flow } from "./Flow";
import { kratos } from "./kratos";
import { FieldInput } from "./NodeFieldInput";
import { NodeInput } from "./NodeInput";
import { OIDCLoginForm } from "./OIDCLoginForm";
import { Placeholders } from "./Placeholders";

const placeholders: Placeholders<UpdateLoginFlowBody> = {
  identifier: "Email",
  password: "Password"
};

export function Login() {
  const [flow, setFlow] = useState<LoginFlow>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isStillMounted = useRef(false);
  useEffect(() => {
    isStillMounted.current = true;
    return () => {
      isStillMounted.current = false;
    };
  });

  useEffect(() => {
    const flowId = searchParams.get("flow");

    if (flow) {
      return;
    }

    if (flowId) {
      kratos
        .getLoginFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data);
        })
        .catch(handleGetFlowError(navigate, "login", setFlow));
      return;
    }

    kratos
      .createBrowserLoginFlow({ returnTo: searchParams.get("return_to") || undefined })
      .then(({ data }) => {
        setFlow(data);
      })
      .catch(handleGetFlowError(navigate, "login", setFlow));
  }, [flow, navigate, searchParams]);

  const onSubmit = useCallback(
    (values: UpdateLoginFlowBody) => {
      return (
        kratos
          .updateLoginFlow({
            flow: String(flow?.id),
            updateLoginFlowBody: values
          })
          // We logged in successfully! Let's bring the user home.
          .then(() => {
            let returnTo = "/";
            try {
              const url = new URL(searchParams.get("return_to"));
              returnTo = url.pathname;
            } catch {}
            navigate(returnTo);
          })
          .catch(handleGetFlowError(navigate, "login", setFlow))
          .catch(err => {
            if (err.response?.status === 400) {
              setFlow(err.response?.data);
              return;
            }

            return Promise.reject(err);
          })
      );
    },
    [flow?.id, navigate, searchParams]
  );

  const passwordLogin = useSelfService({
    flow,
    only: "password",
    onSubmit,
    isStillMounted
  });

  return (
    <>
      <Flow flow={flow} onSubmit={passwordLogin.handleSubmit}>
        {passwordLogin.filteredNodes.map(node => {
          const id = getNodeId(node) as keyof UpdateLoginFlowBody;
          return (
            <NodeInput
              input={FieldInput}
              key={id}
              placeholder={placeholders[id]}
              node={node}
              attributes={node.attributes.node_type === "input" ? node.attributes : undefined}
              disabled={passwordLogin.state.isLoading}
              value={passwordLogin.state.values[id] ?? ""}
              setValue={value => passwordLogin.onChange(id, value)}
            />
          );
        })}
      </Flow>
      {flow?.ui.nodes
        .filter(n => n.group === "oidc")
        .map(node => {
          const id = getNodeId(node) as keyof UpdateLoginFlowBody;
          return (
            <OIDCLoginForm
              key={id}
              node={node}
              onSubmit={onSubmit}
              flow={flow}
              isStillMounted={isStillMounted}
            />
          );
        })}
      <p>
        <Link to="/auth/recovery">Reset password</Link>
      </p>
    </>
  );
}
