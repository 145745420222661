import React, { ChangeEvent, HTMLProps, useCallback } from "react";
import { useFetch } from "usehooks-ts";

import { UserSettingsNodeInput } from "./NodeFieldInput";
import { Validation, validationDefaultMinLength, validationPattern } from "./validation";

export function UserSettingsPasswordInput(props: HTMLProps<HTMLInputElement>) {
  const { data: validation, error } = useFetch<Validation>("/static/validation.json");

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onChange(e);
      const input = e.target;
      input.setCustomValidity("");
      if (input.validity.patternMismatch) {
        const messages: { [key in keyof Validation]: string } = {
          special: "at least one special character",
          uppercase: "at least one uppercase letter",
          lowercase: "at least one lowercase letter",
          numeric: "at least one number",
          minLength: `a length of at least ${validation.minLength || validationDefaultMinLength}`
        };
        input.setCustomValidity(
          "password must meet the following requirements:\n" +
            Object.entries(messages)
              .filter(([k]) => validation[k as keyof Validation])
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([_, v]) => v)
              .join(",\n")
        );
      }
    },
    [props, validation]
  );

  return (
    <UserSettingsNodeInput
      {...props}
      onChange={onChange}
      disabled={props.disabled || !validation || !!error}
      pattern={validation ? validationPattern(validation) : undefined}
    />
  );
}
