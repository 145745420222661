import { Org } from "@src/Generated/graphql";
import { UserSession, useSession } from "@src/Session";

import { useLoggedInUserOrgs } from "./userOrgs";

export function useDefaultOrg() {
  const { session } = useSession();
  const { userOrgs, loading: loadingOrgs } = useLoggedInUserOrgs();

  const identity = session?.identity;

  const defaultOrg = getDefaultOrg(identity, userOrgs);
  return {
    org: defaultOrg,
    loading: !session || loadingOrgs
  };
}

function getDefaultOrg(identity: UserSession["identity"], userOrgs: Org[]) {
  const traits = identity?.traits as { defaultOrg?: string };

  if (traits?.defaultOrg) return traits?.defaultOrg;

  return userOrgs?.find(uo => uo.id && uo.displayName)?.id;
}
