import styled from "styled-components";

export const Clickable = styled.button.attrs({ type: "button" })`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const InlineClickable = styled(Clickable)`
  display: inline-flex;
`;
