import React from "react";
import styled from "styled-components";

import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Title } from "@src/Components/Modal/Modal";
import { useSiteIdSelector } from "@src/Hooks/siteIdSelector";
import { ExpandedObject } from "@src/Hooks/siteTree";
import {
  SiteItem,
  SiteTree,
  StyledTree
} from "@src/ServicesDashboard/Infrastructure/FileTree/SiteTree";

const TreeWrapper = styled.div`
  margin: 30px 0;
`;

const WrapperAlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

interface SiteBrowserProps {
  onSelectSite: (siteId: string, expanded?: ExpandedObject) => void;
  modalTitle: string;
  ancestors?: string[];
  selectedSite?: string;
  cancel: () => void;
}

export function SiteIdSelector({
  modalTitle,
  onSelectSite,
  ancestors,
  selectedSite,
  cancel
}: SiteBrowserProps) {
  const { treeProps, siteIdSelected, handleClick } = useSiteIdSelector({
    onSelectSite,
    ancestors,
    selectedSite
  });
  return (
    <>
      <Title>{modalTitle}</Title>
      <TreeWrapper>
        <SiteTree readOnly {...treeProps} />
      </TreeWrapper>
      <WrapperAlignRight>
        <GhostButton type="button" onClick={cancel}>
          Cancel
        </GhostButton>
        <PrimaryButton type="button" disabled={!siteIdSelected} onClick={handleClick}>
          OK
        </PrimaryButton>
      </WrapperAlignRight>
    </>
  );
}

interface SiteOnlyIdSelectorProps {
  onSelectSite: (siteId: string, expanded?: ExpandedObject) => void;
  modalTitle: string;
  ancestors?: string[];
  sites: string[];
  selectedSite?: string;
  cancel: () => void;
}

export function SiteOnlyIdSelector({
  modalTitle,
  onSelectSite,
  ancestors,
  selectedSite,
  sites,
  cancel
}: SiteOnlyIdSelectorProps) {
  const { treeProps, siteIdSelected, handleClick } = useSiteIdSelector({
    onSelectSite,
    ancestors,
    selectedSite
  });
  const validSiteSelected = selectedSite !== null || siteIdSelected !== ancestors[0];

  return (
    <>
      <Title>{modalTitle}</Title>
      <TreeWrapper>
        <StyledTree>
          {(sites || []).length > 0 ? (
            <>
              {sites.map(s => (
                <SiteItem key={s} siteId={s} ancestors={ancestors} readOnly {...treeProps} />
              ))}
            </>
          ) : (
            <>This Organization does not currently have any sites. Please add one and come back.</>
          )}
        </StyledTree>
      </TreeWrapper>
      <WrapperAlignRight>
        <GhostButton type="button" onClick={cancel}>
          Cancel
        </GhostButton>
        {sites.length !== 0 && (
          <PrimaryButton type="button" disabled={!validSiteSelected} onClick={handleClick}>
            OK
          </PrimaryButton>
        )}
      </WrapperAlignRight>
    </>
  );
}
