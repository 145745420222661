import { ValuesField } from "@src/Generated/graphql";

import { CoreStatus } from "./CoreStatusTag";

export enum NetworkElementType {
  Core = "Core",
  GNB = "gNB"
}

export interface NetworkElement {
  type: NetworkElementType;
  displayname: string;
  status: CoreStatus;
  uid: string;
  latitude: number;
  longitude: number;
  chartname: string;
  charttype: string;
  chartversion: string;
  config: ValuesField[];
}
