import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NoNetwork from "@img/no-network.svg";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { useOrgCtx } from "@src/Hooks/orgCtx";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 30%;
  gap: 20px;
`;
const NoNetworkIcon = styled(NoNetwork)`
  height: 200px;
  fill: #999;
`;

const NewNetworkButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.green};
  &:hover {
    color: white;
    filter: brightness(85%);
  }
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export function NoNetworksPage() {
  const orgCtx = useOrgCtx();
  const navigate = useNavigate();

  const goToAddNetwork = useCallback(() => {
    navigate(`/app/${orgCtx}/mpn/network/add`);
  }, [navigate, orgCtx]);

  return (
    <Wrapper>
      <NoNetworkIcon />
      <Message>No networks available at the moment.</Message>
      <NewNetworkButton onClick={goToAddNetwork}>Add New Network</NewNetworkButton>
    </Wrapper>
  );
}
