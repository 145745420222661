import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { useOrgCtx } from "@src/Hooks/orgCtx";

const Img = styled.img`
  align-self: center;
`;

export function ClientLogo() {
  const orgCtx = useOrgCtx();
  const logoUrl = `/upload/${orgCtx}/logo.png`;

  const img = useRef<HTMLImageElement>();

  useEffect(() => {
    if (orgCtx && img.current) {
      fetch(logoUrl, { cache: "reload" })
        .then(body => body.blob())
        .then(blob => {
          if (img.current) {
            img.current.src = URL.createObjectURL(blob);
          }
        });
    }
  }, [logoUrl, orgCtx]);

  return orgCtx ? <Img ref={img} alt="client logo" /> : null;
}
