import React, { useCallback } from "react";
import { css, keyframes, styled } from "styled-components";

import { FetchResult, MutationFunctionOptions, useMutation } from "@apollo/client";
import { Clickable } from "@src/Components/Buttons/Clickable";
import {
  AddSiteMutation,
  AddSiteMutationVariables,
  SiteTreeItemQuery,
  SiteTreeItemQueryVariables,
  SiteTreeOrgQuery,
  SiteTreeOrgQueryVariables
} from "@src/Generated/graphql";
import { formatNameString } from "@src/Hooks/formatNameStrings";
import { NavigateSiteItem, SiteTreeProps } from "@src/Hooks/siteTree";
import { useToggle } from "@src/Hooks/toggle";
import {
  DisplayNameMiniForm,
  FormValues
} from "@src/ServicesDashboard/Infrastructure/DisplayNameMiniForm";

import AddSite from "./AddSite.graphql";
import { SiteTreeItem } from "./SiteTreeItem.graphql";
import { SiteTreeOrg } from "./SiteTreeOrg.graphql";

const pulseText = keyframes`
  0% {
    opacity: 0.25;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
`;

const AddSiteButton = styled(Clickable)<{ $firstSite: boolean }>`
  position: relative;
  margin-top: 5px;
  justify-content: center;
  font-weight: bold;
  opacity: 0.25;
  color: ${({ theme }) => theme.primary};

  &:hover {
    opacity: 1;
  }

  & > svg {
    height: 12px;
    width: 12px;
  }

  ${({ $firstSite }) =>
    $firstSite &&
    css`
      animation: 2s ${pulseText} infinite;
    `}
`;

interface NewSiteInputProps extends SiteTreeProps {
  ancestors: string[];
  firstSite?: boolean;
}

export function NewSiteInput({ ancestors, firstSite, ...treeProps }: NewSiteInputProps) {
  const [mutate] = useMutation<AddSiteMutation, AddSiteMutationVariables>(AddSite, {
    update: (cache, { data: { createSite } }) => {
      cache.updateQuery<SiteTreeOrgQuery, SiteTreeOrgQueryVariables>(
        {
          query: SiteTreeOrg,
          variables: { id: ancestors[0] }
        },
        data => ({
          ...data,
          org: {
            ...data?.org,
            sites: [...(data?.org?.sites || []), createSite]
          }
        })
      );
    }
  });

  return <SiteInput ancestors={ancestors} firstSite={firstSite} {...treeProps} mutate={mutate} />;
}

export function NewChildSiteInput({ ancestors, firstSite, ...treeProps }: NewSiteInputProps) {
  const [mutate] = useMutation<AddSiteMutation, AddSiteMutationVariables>(AddSite, {
    update: (cache, { data: { createSite } }) => {
      cache.updateQuery<SiteTreeItemQuery, SiteTreeItemQueryVariables>(
        {
          query: SiteTreeItem,
          variables: { id: ancestors[ancestors.length - 1] }
        },
        data => ({
          ...data,
          site: {
            ...data?.site,
            sites: [...(data?.site?.sites || []), createSite]
          }
        })
      );
    }
  });

  return <SiteInput ancestors={ancestors} firstSite={firstSite} mutate={mutate} {...treeProps} />;
}

interface SiteInputProps extends NewSiteInputProps {
  mutate: (options?: MutationFunctionOptions) => Promise<FetchResult>;
}

export function SiteInput({ ancestors, firstSite, selectTreeItem, mutate }: SiteInputProps) {
  const { state: showInput, toggle } = useToggle();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        mutate({
          variables: {
            site: {
              ancestors,
              displayName: formatNameString(values.displayName)
            }
          }
        }).then(({ data }) => {
          const navigateTo: NavigateSiteItem = {
            siteId: data.createSite.id
          };
          selectTreeItem(data.createSite.id, ancestors, navigateTo);
        });
      } catch {}
      toggle();
    },
    [selectTreeItem, mutate, ancestors, toggle]
  );
  return showInput ? (
    <DisplayNameMiniForm handleSubmit={handleSubmit} onBlur={toggle} initialValue="" />
  ) : (
    <AddSiteButton $firstSite={!!firstSite} onClick={toggle} title="new site">
      ＋ add site
    </AddSiteButton>
  );
}
