import React, { Dispatch, useContext, useEffect, useId } from "react";
import styled from "styled-components";

import { Instructions } from "@src/AdminDashboard/MapSettings/MapSettings";
import { Input } from "@src/Components/Input/Input";
import { FieldLabel } from "@src/Components/Input/InputGroup";
import { Device } from "@src/Generated/graphql";
import { DropShadowMarker } from "@src/Hooks/dropShadow";
import { SvgDefTrackerContext } from "@src/Hooks/svgDef";
import { LatLng } from "@src/Map/bounds";
import { LocationSearch } from "@src/Map/LocationSearch";
import { MapboxMap } from "@src/Map/MapboxMap";
import { MapAction, MapState } from "@src/Map/mapReducer";

const TopLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
`;

export const MarkerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const Marker = styled.svg`
  width: 100px;
  height: 100px;
`;

const ReadOnlyInput = styled(Input).attrs({ readOnly: true })`
  margin-bottom: 10px;
  align-self: flex-start;
  flex-basis: 400px;
  background: ${({ theme }) => theme.backgroundLight};
`;

const MapInstructions = styled(Instructions)`
  margin-top: -2em;
`;

const SearchLabel = styled.p`
  padding: 0 10px;
`;

const MapPoint = styled.circle`
  fill: #ccc;
  stroke: #4a4a4a;
  stroke-width: 1;
  filter: url(#dropShadow);
`;

interface PositionSelectorProps {
  fieldValue: LatLng;
  setFieldValue?: (center: LatLng) => void;
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  referenceDevice?: Device;
  hideLabel?: boolean;
  mapHeight?: string;
}

export function PositionSelector({
  fieldValue,
  setFieldValue,
  mapState,
  dispatch,
  hideLabel = false,
  mapHeight
}: PositionSelectorProps) {
  const { lat, lng } = fieldValue;

  useEffect(() => {
    if (!setFieldValue) return;
    const { latitude: lat, longitude: lng } = mapState;
    setFieldValue({ lat, lng });
  }, [setFieldValue, mapState]);

  const { state } = useContext(SvgDefTrackerContext);

  return (
    <>
      {!hideLabel && <FieldLabel>position</FieldLabel>}
      <ReadOnlyInput value={`${lat}, ${lng}`} />

      <TopLine>
        <SearchLabel>Search for location</SearchLabel>
        <LocationSearch mapState={mapState} dispatch={dispatch} />
      </TopLine>
      <MapboxMap mapState={mapState} dispatch={dispatch} mapHeight={mapHeight}>
        <MapInstructions>
          Drag the map or search for location to set the device position
        </MapInstructions>
        <MarkerContainer>
          <Marker>
            <MapPoint r="4" cx={48} cy={48} />
          </Marker>
        </MarkerContainer>
        <DropShadowMarker svgDefState={state} markerId={useId()} />
      </MapboxMap>
    </>
  );
}
