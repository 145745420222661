import { UseSelectStateChange } from "downshift";
import React, { useCallback } from "react";
import styled from "styled-components";

import { DropdownMenuItemWrapper } from "@src/Components/DropdownMenu";

import { Dropdown, Select } from "./Dropdown";

const OrgSelect = styled(Select)`
  padding: 8px;
  height: auto;
  font-size: 12px;
`;

export const SelectorWrapper = styled.div`
  min-width: 150px;
  max-width: 300px;
`;

const OrgOptionElement = styled(DropdownMenuItemWrapper)`
  font-size: 12px;
`;
const AllOrgsOptionElement = styled(OrgOptionElement)`
  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

interface Organization {
  id: string;
  displayName?: string;
}

export interface OrgSelectorProps {
  selectedOrg: string;
  organizations: Organization[];
  selectOrg: (org: string) => void;
  loading?: boolean;
}

export function OrgSelector({
  selectedOrg,
  organizations,
  selectOrg,
  loading = false
}: OrgSelectorProps) {
  const onSelect = useCallback(
    ({ selectedItem: org }: UseSelectStateChange<Organization>) => {
      selectOrg(org.id);
    },
    [selectOrg]
  );
  const initialOrg = organizations.find(org => org.id === selectedOrg);
  const sortedOrgs = organizations.filter(org => org?.displayName).sort(sortOrganizations);

  const getKey = useCallback((org: Organization) => org?.id, []);
  const getName = useCallback((org: Organization) => org?.displayName, []);

  return (
    <SelectorWrapper>
      <Dropdown
        SelectElement={OrgSelect}
        OptionElement={
          sortedOrgs.at(0)?.id === allOrgsItem.id ? AllOrgsOptionElement : OrgOptionElement
        }
        getItemDisplayName={getName}
        getItemKey={getKey}
        items={sortedOrgs}
        initialSelectedItem={initialOrg}
        onSelectedItemChange={onSelect}
        placeholder="select organization"
        loading={loading}
      />
    </SelectorWrapper>
  );
}

export const allOrgsItem = { id: "all-organizations", displayName: "All Organizations" };

function sortOrganizations(orgA: Organization, orgB: Organization) {
  if (orgA.id === allOrgsItem.id) return -1;
  if (orgB.id === allOrgsItem.id) return 1;

  return orgA.displayName.localeCompare(orgB.displayName);
}
