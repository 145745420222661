import { useQuery } from "@apollo/client";
import { useDeepCompareDebounce } from "@src/Hooks/debounce";

import { DevicesQuery, DevicesResult, TagInput } from "../../Generated/graphql";
import { Bounds } from "../../Map/bounds";
import Devices from "./Devices.graphql";

interface DevicesQueryProps {
  skip: boolean;
  tags: TagInput[];
  bbox: Bounds;
}

export function useDebouncedDevicesQuery({ skip, tags, bbox }: DevicesQueryProps): DevicesResult {
  const variables = useDeepCompareDebounce(
    {
      tags,
      bbox
    },
    100
  );

  const { data } = useQuery<DevicesQuery>(Devices, {
    skip,
    variables
  });

  if (!data) return { __typename: "DevicesResult", devices: [] };
  return data.devices;
}
