export interface TimeRangeOption {
  from: string;
  to: string;
  label: string;
}

export const validTimeStrings = [
  "now",
  "now-5m",
  "now-15m",
  "now-30m",
  "now-1h",
  "now-3h",
  "now-6h",
  "now-12h",
  "now-24h",
  "now-1w",
  "now-1M"
];

export const quickRangeOptions: TimeRangeOption[] = [
  { from: "now-5m", to: "now", label: "Last-5-minutes" },
  { from: "now-15m", to: "now", label: "Last-15-minutes" },
  { from: "now-30m", to: "now", label: "Last-30-minutes" },
  { from: "now-1h", to: "now", label: "Last-1-hour" },
  { from: "now-3h", to: "now", label: "Last-3-hours" },
  { from: "now-6h", to: "now", label: "Last-6-hours" },
  { from: "now-12h", to: "now", label: "Last-12-hours" },
  { from: "now-24h", to: "now", label: "Last-24-hours" },
  { from: "now-1w", to: "now", label: "Last-week" },
  { from: "now-1M", to: "now", label: "Last-month" }
];

export const supportedQuickUnits = ["m", "h"];

export function parseQuickRangeOption(range: string, testDate?: Date) {
  const date = testDate ? testDate : new Date();

  if (!range) return date.getTime();
  const timeUnit = range.slice(-1);
  const value = range.substring(4, range.length - 1);

  if (range === "now") return date.getTime();

  switch (timeUnit) {
    case "m":
      return date.setMinutes(date.getMinutes() - Number(value));
    case "h":
      return date.setHours(date.getHours() - Number(value));
    case "w":
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6).getTime();
    case "M":
      return date.setMonth(date.getMonth() - Number(value));
    default:
      return date.getTime();
  }
}
