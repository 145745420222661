import { FormikErrors } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";

import { Clickable } from "@src/Components/Buttons/Clickable";
import { baseInputStyles } from "@src/Components/Input/Input";
import { InputErrors } from "@src/Components/Input/InputGroup";
import { Modal } from "@src/Components/Modal/Modal";
import { SiteIdSelector } from "@src/Components/SiteIdSelector";
import { useParentSiteInfo } from "@src/Hooks/parentSiteInfo";
import { useToggle } from "@src/Hooks/toggle";

const SiteSelectorButton = styled(Clickable)`
  ${baseInputStyles}
  &:disabled {
    background: ${({ theme }) => theme.backgroundLight};
    opacity: 1;
    &:focus {
      outline: none;
    }
  }
`;
const InputErrorsWrapper = styled.span`
  grid-column: 2;
`;

interface SiteSelectorInputProps<E> {
  name: string;
  modalTitle: string;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
  siteId: string;
  errors: FormikErrors<E>;
  disabled?: boolean;
}

export function SiteSelectorInput<E>({
  name,
  modalTitle,
  setFieldValue,
  siteId,
  errors,
  disabled = false
}: SiteSelectorInputProps<E>) {
  const { state, toggle } = useToggle();
  const selectSite = useCallback(
    (siteId: string) => {
      setFieldValue(name, siteId);
      toggle();
    },
    [setFieldValue, toggle, name]
  );

  const { parentSiteName } = useParentSiteInfo(siteId);

  return (
    <>
      <SiteSelectorButton onClick={toggle} disabled={disabled}>
        {parentSiteName || "Select Site"}
      </SiteSelectorButton>
      <InputErrorsWrapper>
        <InputErrors name={name} errors={errors} />
      </InputErrorsWrapper>
      <Modal show={state} onOutsideClick={toggle}>
        <SiteIdSelector
          onSelectSite={selectSite}
          modalTitle={modalTitle}
          selectedSite={siteId}
          cancel={toggle}
        />
      </Modal>
    </>
  );
}
