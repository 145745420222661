import { Dispatch, useEffect } from "react";

import { useQuery } from "@apollo/client";
import MapSettings from "@src/AdminDashboard/MapSettings/Settings.graphql";
import { MapSettingsQuery } from "@src/Generated/graphql";

import { checkMapReady, MapAction, MapState } from "./mapReducer";

export const spain = {
  center: { lat: 40.212440718286466, lng: -3.5705566406250004 },
  zoom: 6
};

export function useMapSettings(mapState: MapState, dispatch: Dispatch<MapAction>, skip?: boolean) {
  const { data, error } = useQuery<MapSettingsQuery>(MapSettings, { skip });
  const map = data?.settings?.map;
  const mapIsReady = checkMapReady(mapState);

  useEffect(() => {
    if (mapIsReady) return;

    if (map) {
      dispatch({ type: "init", center: map.center, zoom: map.zoom });
    } else if (error) {
      dispatch({ type: "init", center: spain.center, zoom: spain.zoom });
    }
  }, [mapIsReady, map, dispatch, error]);

  return mapIsReady;
}
