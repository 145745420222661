import React, { memo } from "react";
import { createPortal } from "react-dom";

import { SVG, SvgDefTrackerState, useSvgDef } from "./svgDef";

const filter = (
  <filter id="dropShadow" x="-20%" y="-20%" width="200%" height="200%">
    <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
  </filter>
);

interface DropShadowMarkerProps {
  svgDefState: SvgDefTrackerState;
  markerId: string;
}
export const DropShadowMarker = memo(function useDropShadowDef({
  svgDefState,
  markerId
}: DropShadowMarkerProps) {
  const markerState = svgDefState["dropShadow"];
  useSvgDef("dropShadow", markerId);
  return markerState?.instanceId === markerId
    ? createPortal(<SVG>{filter}</SVG>, document.body)
    : null;
});
