import * as Yup from "yup";

import { ReviewFieldType } from "@src/Components/Review/FieldReview";
import { ConfigField, yupConfigSchema } from "@src/DynamicForm/initFormConfig";
import { ChartKey, LatLng } from "@src/Generated/graphql";

import { FieldInfo } from "./RenderedInputs";

export interface ProvisionCloudResourceValues {
  displayName: string;
  site: string;
  position: {
    lat: number;
    lng: number;
  };
  controllerSite: string;
  chart: ChartKey;
  config: ConfigField[];
}

export interface EditCloudResourceDeviceValues {
  displayName: string;
  config: ConfigField[];
  position: {
    lat: number;
    lng: number;
  };
}

const sharedCloudResourceValues = {
  displayName: Yup.string().required("display name is required"),
  config: yupConfigSchema,
  position: Yup.object<LatLng>().shape({
    lat: Yup.number().label("latitude").required("latitude is required"),
    lng: Yup.number().label("longitude").required("longitude is required")
  })
};

export const addCloudResourceSchema = Yup.object<ProvisionCloudResourceValues>().shape({
  ...sharedCloudResourceValues,
  site: Yup.string().required(),
  controllerSite: Yup.string().required(),
  chart: Yup.object<ChartKey>().shape({
    name: Yup.string().required(),
    version: Yup.string().required()
  })
});

export const updateCloudResourceSchema =
  Yup.object<EditCloudResourceDeviceValues>().shape(sharedCloudResourceValues);

export function compareValueState(fieldInfo: Partial<FieldInfo>) {
  const { initialValue, value } = fieldInfo;
  const isDefaultUnsetValue = initialValue == null && (value === "" || value === false);
  return String(initialValue) === String(value) || isDefaultUnsetValue;
}

export function serialiseReviewFields(config: ConfigField[], isEdit: boolean) {
  const searialisedConfig = config.map(field => {
    const { value, initialValue, selected } = field;
    let reviewDisplayValue;
    let reviewType: ReviewFieldType;
    if (selected && !compareValueState(field)) {
      reviewDisplayValue = value === "" ? "❛❛ ❜❜" : value;
      reviewType = isEdit ? "modified" : "override";
    } else if (!selected && initialValue == null) {
      reviewDisplayValue = undefined;
      reviewType = "unset";
    } else {
      reviewDisplayValue = initialValue === "" ? "❛❛ ❜❜" : initialValue;
      reviewType = isEdit ? "unmodified" : "default";
    }
    return {
      ...field,
      reviewType,
      value: reviewDisplayValue
    };
  });
  return searialisedConfig;
}
