import { ObjectType } from "@src/Clients/Perminator/data-contracts";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanCreateBlockChart(orgId: string) {
  return usePermissionCheck({
    objectType: ObjectType.Org,
    objectId: orgId,
    relation: "createBlockChart"
  });
}
