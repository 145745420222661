import { UseSelectStateChange } from "downshift";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";

import { SettingsFlow, UpdateSettingsFlowBody } from "@ory/client";
import { OrgId } from "@src/AdminDashboard/Users/Management/OrgFormField";
import { handleGetFlowError } from "@src/Auth/errors";
import { Flow } from "@src/Auth/Flow";
import { kratos } from "@src/Auth/kratos";
import { UserSettingsNodeInput } from "@src/Auth/NodeFieldInput";
import { NodeInput } from "@src/Auth/NodeInput";
import { Placeholders } from "@src/Auth/Placeholders";
import { UserSettingsPasswordInput } from "@src/Auth/UserSettingsPasswordInput";
import { Dropdown, Select } from "@src/Components/Dropdown";
import { DropdownMenuItemWrapper } from "@src/Components/DropdownMenu";
import { baseInputStyles } from "@src/Components/Input/Input";
import { ErrorLine, FieldLabel } from "@src/Components/Input/InputGroup";
import { ToastNotification } from "@src/Components/ToastNotification";
import { Org } from "@src/Generated/graphql";
import { getNodeId, Methods, useSelfService } from "@src/Hooks/selfService";
import { useLoggedInUserOrgs } from "@src/Hooks/userOrgs";

const SelectDefaultOrg = styled(Select)`
  ${baseInputStyles}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
`;

const Option = styled(DropdownMenuItemWrapper)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const NodeInputWrapper = styled.div`
  grid-column: 2;
`;

const placeholders: Placeholders<UpdateSettingsFlowBody> = {
  "traits.email": "Email",
  "traits.name": "Name",
  password: "Password"
};

type defaultOrg = "traits.defaultOrg";
const defaultOrg: defaultOrg = "traits.defaultOrg";

type ExtendedUpdateSettingsFlowBody = UpdateSettingsFlowBody & {
  [defaultOrg]: string;
};

interface UserSettingsFormProps {
  only: Methods;
  flow?: SettingsFlow;
  setFlow: (flow: SettingsFlow) => void;
  navigate: NavigateFunction;
}

export function UserSettingsForm({ only, flow, setFlow, navigate }: UserSettingsFormProps) {
  const [selectedOrg, setSelectedOrg] = useState(flow?.identity?.traits?.defaultOrg || "");

  const onSubmit = useCallback(
    (values: ExtendedUpdateSettingsFlowBody) => {
      const updateSettingsFlowBody =
        values.method === "profile"
          ? {
              ...values,
              [defaultOrg]: selectedOrg
            }
          : values;
      return kratos
        .updateSettingsFlow({
          flow: String(flow?.id),
          updateSettingsFlowBody
        })
        .then(({ data }) => {
          setFlow(data);
          toast.success(<ToastNotification title={`${only} updated successfully`} />);
        })
        .catch(handleGetFlowError(navigate, "settings", setFlow))
        .catch(err => {
          if (err.response?.status === 400) {
            setFlow(err.response?.data);
            toast.error(<ToastNotification title={`an error ocurred updating your ${only}`} />);
            return;
          }
          return Promise.reject(err);
        });
    },
    [navigate, flow, setFlow, only, selectedOrg]
  );

  const { filteredNodes, state, onChange, handleSubmit } = useSelfService({
    flow,
    only,
    onSubmit
  });

  return (
    <Flow flow={flow} onSubmit={handleSubmit} isSettingsPage hideGlobalMessages>
      {filteredNodes.map(node => {
        const id = getNodeId(node) as keyof UpdateSettingsFlowBody;
        const attributes = node.attributes.node_type === "input" ? node.attributes : undefined;
        return (
          <React.Fragment key={id}>
            {attributes.type !== "submit" && node.group === "profile" && (
              <FieldLabel htmlFor={attributes.name}>{node.meta.label?.text}</FieldLabel>
            )}
            {attributes.name === defaultOrg ? (
              <DefaultOrgDropdown selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
            ) : (
              <NodeInputWrapper>
                <NodeInput
                  input={
                    attributes.name === "password"
                      ? UserSettingsPasswordInput
                      : UserSettingsNodeInput
                  }
                  placeholder={placeholders[id]}
                  node={node}
                  attributes={attributes}
                  disabled={state.isLoading}
                  value={state.values[id] ?? ""}
                  setValue={value => onChange(id, value)}
                />
                {(node.messages || []).map(msg =>
                  msg.type === "error" ? (
                    <ErrorLine key={msg.id}>{msg.text}</ErrorLine>
                  ) : (
                    <p key={msg.id}>{msg.text}</p>
                  )
                )}
              </NodeInputWrapper>
            )}
          </React.Fragment>
        );
      })}
    </Flow>
  );
}

interface DefaultOrgDropdownProps {
  selectedOrg: string;
  setSelectedOrg: (org: string) => void;
}
function DefaultOrgDropdown({ selectedOrg, setSelectedOrg }: DefaultOrgDropdownProps) {
  const { userOrgs, loading } = useLoggedInUserOrgs();

  const getOrgDisplayName = useCallback((org: Org) => {
    if (!org) return null;
    return (
      <>
        {org.displayName} <OrgId>{org.id}</OrgId>
      </>
    );
  }, []);

  const getOrgKey = useCallback(({ id }: Org) => id, []);

  const onSelectOrg = useCallback(
    (change: UseSelectStateChange<Org>) => {
      setSelectedOrg(change.selectedItem.id);
    },
    [setSelectedOrg]
  );

  if (loading) return null;
  const initialOrg = (userOrgs || []).find(org => org.id === selectedOrg);

  return (
    <div>
      <Dropdown
        SelectElement={SelectDefaultOrg}
        OptionElement={Option}
        getItemDisplayName={getOrgDisplayName}
        getItemKey={getOrgKey}
        items={userOrgs}
        initialSelectedItem={initialOrg}
        onSelectedItemChange={onSelectOrg}
        placeholder="select default organization"
        handleOverflow={false}
      />
    </div>
  );
}
