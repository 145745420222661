import React from "react";

import CompletionsIcon from "@img/completions.svg";
import CustomizationIcon from "@img/customizations.svg";
import GroupsIcon from "@img/groups.svg";
import MapIcon from "@img/map.svg";
import OrgIcon from "@img/org.svg";
import UserIcon from "@img/user-filled.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/orgCtx";

export function AdminNavbar() {
  const orgCtx = useOrgCtx();
  const rootPath = `/app/${orgCtx}/admin/`;

  if (!orgCtx) return <OrgLessNavbar />;
  return (
    <NavIcons>
      <NavItem linkTo={rootPath + "map-settings"} label="Map Settings" icon={MapIcon} />
      <NavItem linkTo={rootPath + "completions"} label="Completions" icon={CompletionsIcon} />
      <NavItem
        linkTo={rootPath + "customizations"}
        label="Customization"
        icon={CustomizationIcon}
      />
      <NavItem linkTo={rootPath + "users"} label="Users" icon={UserIcon} />
      <NavItem linkTo={rootPath + "groups"} label="Groups" icon={GroupsIcon} />
      <NavItem linkTo={rootPath + "organizations"} label="Organizations" icon={OrgIcon} />
    </NavIcons>
  );
}

function OrgLessNavbar() {
  return (
    <NavIcons>
      <NavItem linkTo={"/app/admin/organizations"} label="Organizations" icon={OrgIcon} />
    </NavIcons>
  );
}
