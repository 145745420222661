// by default in Kratos, the minimum password length is 8
export const validationDefaultMinLength = 8;

export type Validation = {
  special: boolean;
  uppercase: boolean;
  lowercase: boolean;
  numeric: boolean;
  minLength: number;
};

type LookaheadRules = {
  [K in keyof Validation as K extends "minLength" ? never : K]: string;
};

const contains = (chars: string) => `(?=.*[${chars}])`;

export const validationRules: LookaheadRules = {
  special: contains(" !\"#$%&'\\(\\)*+,\\-.\\/:;<=>?@\\[\\\\\\]^_`\\{\\|\\}~"),
  uppercase: contains("\\p{Lu}"),
  lowercase: contains("\\p{Ll}"),
  numeric: contains("\\p{N}")
};

export const validationPattern = (v: Validation) =>
  Object.keys(v)
    .filter((key: keyof Validation) => v[key] && validationRules.hasOwnProperty(key))
    .map((key: keyof LookaheadRules) => validationRules[key] || "")
    .join("") + `.{${v.minLength || validationDefaultMinLength},}`;
