import styled from "styled-components";

import ReloadIcon from "@img/reset.svg";

export const Reload = styled(ReloadIcon)`
  width: 15px;
  padding: 0 5px;
  flex-shrink: 0;
  cursor: pointer;

  opacity: 0.2;

  &:hover {
    opacity: 1;
  }
`;
