import { Field, Form, Formik } from "formik";
import React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { Input } from "@src/Components/Input/Input";

const SiteInput = styled(Input)`
  height: 30px;
  padding: 5px 10px;
  margin: 5px 0;
`;

const SiteForm = styled(Form)`
  padding: 0 2px 0 8px;
`;

export interface FormValues {
  displayName: string;
}
const schema = Yup.object<FormValues>({
  displayName: Yup.string().required()
});
interface DisplayNameMiniFormProps {
  initialValue: string;
  handleSubmit: (values: FormValues) => void;
  onBlur: () => void;
}

export function DisplayNameMiniForm({
  initialValue,
  handleSubmit,
  onBlur
}: DisplayNameMiniFormProps) {
  return (
    <Formik<FormValues>
      initialValues={{ displayName: initialValue }}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <SiteForm onBlur={onBlur}>
        <Field as={SiteInput} autoFocus name="displayName" />
        <input type="submit" hidden />
      </SiteForm>
    </Formik>
  );
}
