import axios from "axios";
import { useMemo } from "react";
import { useQuery as useReactQuery, UseQueryOptions } from "react-query";

import { ConfigField } from "@src/DynamicForm/initFormConfig";
import { ValuesField } from "@src/Generated/graphql";
import { useNetworkCtx } from "@src/Hooks/networkCtx";
import { useOrgCtx } from "@src/Hooks/orgCtx";

import { mpnRequestUrl, QueryCallbacks } from "../mpnRequests";
import { coreChartDetailsKey, coreChartsListKey } from "./coresQueryKeys";
import { NetworkElement } from "./NetworkElement";
import {
  InventoryResponse,
  serialiseField,
  serialiseNetworkResources
} from "./serialiseNetworkResources";

export function fetchCores(
  orgId: string,
  networkId: string,
  callbacks?: QueryCallbacks
): Promise<InventoryResponse[]> {
  const url = mpnRequestUrl({
    path: "/mobile-networks/core/tmf-api/resourceInventoryManagement/v4/resource",
    orgId,
    networkId
  });
  return axios.get(url.href).then(response => {
    if (!callbacks) return response.data;

    callbacks.onComplete?.(response.data);
    return response.data;
  });
}

function fetchCoreDetails(resourceId: string): Promise<InventoryResponse> {
  const url = mpnRequestUrl({
    path: `/mobile-networks/core/tmf-api/resourceInventoryManagement/v4/resource/${resourceId}`
  });
  return axios.get(url.href).then(response => response?.data);
}

export function useFetchCoreList(
  key: string[],
  options?: UseQueryOptions<InventoryResponse[]>,
  callbacks?: QueryCallbacks<InventoryResponse[]>
) {
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  const { data, isLoading, error } = useReactQuery(
    [...key, networkCtx, orgCtx],
    () => fetchCores(orgCtx, networkCtx, callbacks),
    options
  );

  const serialisedData = useMemo(() => serialiseNetworkResources(data) as NetworkElement[], [data]);

  return { data: serialisedData || [], loading: isLoading, error };
}

export function useFetchCoreDetails(resourceId: string) {
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  const { data, isLoading } = useReactQuery(
    ["core-details", resourceId, orgCtx, networkCtx],
    () => fetchCoreDetails(resourceId),
    {
      enabled: !!resourceId,
      cacheTime: 0
    }
  );

  const resource = useMemo(() => serialiseField(data) as NetworkElement, [data]);

  return {
    data: {
      resource: isLoading ? null : resource
    },
    loading: isLoading
  };
}

export function getCoreIdentifier(core: NetworkElement) {
  if (!core) return "";
  if (core.uid === "all-cores") return core.displayname;
  return `${core?.displayname} - ${core?.uid}`;
}

type NetworkResourceChartType = "mn-core" | "mn-gnb";

export interface CoreChart {
  name: string;
  version: string;
  serviceSpecification: {
    href: string;
  };
}

function fetchCoreCharts(
  type: NetworkResourceChartType,
  chartName: string,
  orgId: string
): Promise<CoreChart[]> {
  const url = new URL(
    `/service-management/org/${orgId}/tmf-api/serviceCatalogManagement/v4/serviceCandidate`,
    window.location.origin
  );

  url.searchParams.append("category.id", type);
  if (chartName) url.searchParams.append("name", chartName);
  return axios.get(url.href).then(response => response?.data);
}

export function useFetchCoreCharts(type: NetworkResourceChartType, chartName: string) {
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  const {
    data,
    isLoading: loading,
    isFetching,
    refetch
  } = useReactQuery(
    [coreChartsListKey, type, chartName, orgCtx, networkCtx],
    () => fetchCoreCharts(type, chartName, orgCtx),
    {
      refetchOnWindowFocus: false
    }
  );

  return { charts: data || [], loading, isFetching, refetch };
}

export interface ChartDetailsResponse extends Omit<CoreChart, "serviceSpecification"> {
  specCharacteristic: {
    characteristicValueSpecification: { value: Omit<ValuesField, "__typename">[] }[];
  }[];
}

export interface ChartDetails {
  name: string;
  version: string;
  config: ConfigField[];
}

function fetchChartDetails(
  selectedChart: CoreChart,
  callbacks?: QueryCallbacks
): Promise<ChartDetailsResponse> {
  const url = mpnRequestUrl({
    path: selectedChart.serviceSpecification.href
  });

  return axios.get(url.href).then(response => {
    if (!callbacks) return response.data;

    callbacks.onComplete?.(response.data);
    return response?.data;
  });
}

export function useFetchChartDetails(
  selectedChart: CoreChart,
  callbacks?: QueryCallbacks<ChartDetailsResponse>
) {
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  const { data, isLoading: loading } = useReactQuery(
    [coreChartDetailsKey, selectedChart?.name, selectedChart?.version, orgCtx, networkCtx],
    () => fetchChartDetails(selectedChart, callbacks),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!selectedChart
    }
  );

  const parsedData = useMemo(() => parseChartDetails(data), [data]);

  return { chart: !!data ? parsedData : null, loading };
}

export function parseChartDetails(details: ChartDetailsResponse) {
  return {
    name: details?.name,
    version: details?.version,
    config: details?.specCharacteristic?.at(0)?.characteristicValueSpecification?.at(0)?.value
  };
}
