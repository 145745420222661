import React, { useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import Download from "@img/download.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { useOrgCtx } from "@src/Hooks/orgCtx";
import { useToggle } from "@src/Hooks/toggle";

import { HeaderItem, HeaderLabel, MenuA, Separator, UserMenu } from "../Header/HeaderStyles";

const DownloadsMenu = styled(UserMenu)`
  width: 280px;
`;

export function ServiceDashboardHeaderItems() {
  const {
    state: isOpenDownload,
    toggle: toggleMenuDownloads,
    off: closeMenuDownloads
  } = useToggle();

  const downloadsMenu = useRef();
  useOnClickOutside(downloadsMenu, closeMenuDownloads);
  const orgCtx = useOrgCtx();

  return (
    <HeaderItem ref={downloadsMenu}>
      <Clickable onClick={toggleMenuDownloads}>
        <Download height={20} width={20} />
        <HeaderLabel>Downloads</HeaderLabel>
        {isOpenDownload ? <UpArrow height={14} width={14} /> : <DownArrow height={14} width={14} />}
      </Clickable>
      {isOpenDownload && (
        <DownloadsMenu>
          <Separator />
          <MenuA href={`/upload/${orgCtx}/provision.iso`} title="download Provisioner ISO" download>
            Provisioner ISO (Beta)
          </MenuA>
          <Separator />
          <MenuA
            href={`/upload/${orgCtx}/onboard.tgz`}
            title="download K8s Cloudlet onboarding helm chart"
            download
          >
            K8s Cloudlet onboarding helm chart
          </MenuA>
          <Separator />
          <MenuA
            href={`/upload/${orgCtx}/onboard.ova`}
            title="download vSphere Cluster onboarding OVA"
            download
          >
            vSphere Cluster onboarding OVA
          </MenuA>
        </DownloadsMenu>
      )}
    </HeaderItem>
  );
}
