// redirectNetwork returns the new path that we should navigate to when switching networks
// it strips parts of the URL that refer to specific resources that should no longer be accessible
export function redirectNetwork(pathname: string, network: string) {
  let match = pathname.match(/^(?<base>\/app\/[^/]+\/mpn)\/network\/(add|[^/]+\/edit)$/);
  if (match && match.groups) {
    return `${match.groups.base}/${network}`;
  }

  match = pathname.match(/^(?<base>\/app\/(?<org>[^/]+)\/mpn)\/(?<network>[^/]+)\/(?<path>.*)$/);
  if (!(match && match.groups)) return null;

  const coreRouteRedirect = matchCoreRoutes(match.groups.path);

  if (coreRouteRedirect) return `${match.groups.base}/${network}/${coreRouteRedirect}`;

  return `${match.groups.base}/${network}/${match.groups.path.replace(
    /\/(core|manage)\/[^/]+$/,
    ""
  )}`;
}

function matchCoreRoutes(path: string) {
  const matchCoreRoute = path.match(
    /^(?<base>(operate\/(events|alarms)|configure|sim-cards))\/[^/]+(?<savedPath>\/.*)?(?<rest>\/.*)?$/
  );

  const savePath = matchCoreRoute?.groups?.base?.includes("configure");

  if (!(matchCoreRoute && matchCoreRoute?.groups)) return null;

  const savedPath = matchCoreRoute?.groups?.savedPath?.replace("/", "");

  return `${matchCoreRoute.groups.base}${!savePath ? "" : `?prev-route=${savedPath}`}`;
}
