import { ObjectType } from "@src/Clients/Perminator/data-contracts";

import { PermissionArgs, usePermissionCheck } from "../permissionsCheck";

export const viewFederationPerms: (orgId: string) => PermissionArgs = orgId => ({
  objectType: ObjectType.Org,
  objectId: orgId,
  relation: "viewFederation"
});

export function useCanViewFederation(orgId: string) {
  return usePermissionCheck(viewFederationPerms(orgId));
}
