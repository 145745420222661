import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { boxShadow } from "@src/Components/Table/Table";
import { headerMenusZIndex } from "@src/Components/zIndexValues";

export const linkStyles = css`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #666666;
  text-decoration: none;
`;

export const MenuA = styled.a`
  ${linkStyles}
`;

export const MenuLink = styled(Link)`
  ${linkStyles}
`;

export const UserMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 16px;
  box-sizing: border-box;
  cursor: default;
  z-index: ${headerMenusZIndex};
  ${boxShadow}
`;

export const HeaderLabel = styled.span`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #4a4a4a;
  padding: 8px;
`;

export const Separator = styled.hr`
  color: #dddddd;
  width: 100%;
  margin: 14px 0;
`;

export const HeaderItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
