import React from "react";
import { Navigate } from "react-router-dom";

import { useDefaultOrg } from "@src/Hooks/defaultOrg";

export function InitialRedirect() {
  const { org, loading } = useDefaultOrg();

  if (loading) return null;

  if (!org) return <Navigate to="/app/no-org" />;

  return <Navigate to={`app/${org}`} />;
}
