import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { LoginFlow, UpdateLoginFlowBody } from "@ory/client";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { DismissModalButton } from "@src/Components/Modal/DismissModalButton";
import { H2 } from "@src/Components/Text";
import { ToastNotification } from "@src/Components/ToastNotification";
import { getNodeId, useSelfService } from "@src/Hooks/selfService";

import { handleGetFlowError } from "./errors";
import { Flow } from "./Flow";
import { kratos } from "./kratos";
import { FieldInput } from "./NodeFieldInput";
import { NodeInput } from "./NodeInput";
import { Placeholders } from "./Placeholders";

const RenewTitle = styled(H2)`
  margin-top: 0;
  font-size: 24px;
`;
const RenewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & p {
    font-size: 14px;
  }
`;

const CancelRenew = styled(GhostButton)`
  width: 100%;
  height: auto;
  border: 2px solid;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 6px 24px;
`;

const placeholders: Placeholders<UpdateLoginFlowBody> = {
  identifier: "Email",
  password: "Password"
};

interface RenewSessionModalProps {
  closeModal: () => void;
}

export function RenewSessionModal({ closeModal }: RenewSessionModalProps) {
  const [flow, setFlow] = useState<LoginFlow>();

  const navigate = useNavigate();

  const isStillMounted = useRef(false);
  useEffect(() => {
    isStillMounted.current = true;
    return () => {
      isStillMounted.current = false;
    };
  });

  useEffect(() => {
    toast.dismiss("session");
    kratos
      .createBrowserLoginFlow({ refresh: true })
      .then(({ data }) => {
        setFlow({
          ...data,
          ui: {
            ...data.ui,
            messages: [
              {
                id: 1,
                type: "info",
                text: "Please enter your password to confirm that it's you."
              }
            ]
          }
        });
      })
      .catch(handleGetFlowError(navigate, "login", setFlow));
  }, [navigate]);

  const onSubmit = useCallback(
    (values: UpdateLoginFlowBody) => {
      return kratos
        .updateLoginFlow({
          flow: String(flow?.id),
          updateLoginFlowBody: values
        })
        .then(() => {
          toast.success(<ToastNotification title="Session renewed successfully" />);
          closeModal();
        })
        .catch(handleGetFlowError(navigate, "login", setFlow))
        .catch(err => {
          if (err.response?.status === 400) {
            setFlow(err.response?.data);
            return;
          }

          return Promise.reject(err);
        });
    },
    [closeModal, flow?.id, navigate]
  );

  const passwordLogin = useSelfService({
    flow,
    only: "password",
    onSubmit,
    isStillMounted
  });

  const customPasswordLoginNodes = (passwordLogin?.filteredNodes || []).map(node => {
    const type = (node?.attributes as { type?: string })?.type;
    return type !== "submit"
      ? node
      : {
          ...node,
          meta: {
            ...node.meta,
            label: {
              ...node.meta.label,
              text: "Continue Online"
            }
          }
        };
  });

  return (
    <RenewWrapper>
      <DismissModalButton onClick={closeModal} />
      <RenewTitle>Renew your Session</RenewTitle>
      <Flow flow={flow} onSubmit={passwordLogin.handleSubmit}>
        {customPasswordLoginNodes.map(node => {
          const id = getNodeId(node) as keyof UpdateLoginFlowBody;
          return (
            <NodeInput
              input={FieldInput}
              key={id}
              placeholder={placeholders[id]}
              node={node}
              attributes={node.attributes.node_type === "input" ? node.attributes : undefined}
              disabled={passwordLogin.state.isLoading}
              value={passwordLogin.state.values[id] ?? ""}
              setValue={value => passwordLogin.onChange(id, value)}
            />
          );
        })}
        <CancelRenew onClick={closeModal}>cancel</CancelRenew>
      </Flow>
    </RenewWrapper>
  );
}
